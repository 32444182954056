
.ProjectBujum {
  margin-top: 40px;
}
h4 {
  margin-left: 10px;
  font-size: bold;
  padding-top: 40px;
}
.custom-card-spacing {
 
  padding: 2rem;
}