.testimonials {
  
  margin-top: 20px;
  
}
.custom-class {
  background-color: hsl(240, 7%, 86%);
  
}
.song {
  width: auto ;
  display: flex;
  flex-direction: column;
  align-items: left;

}
.Button {
  width: 40px;
}
.custom-cardtext {
  text-align: center;
}
.custom-cardtitle{
  font-weight: bold;
}
.custom-card-spacing {
 
  padding: 3rem;
 
}
.video-wrapper{
  border-radius: 7px;
}
.testimonials .titlefont {
  font-size: 24px !important;
  font-weight: bold;
}

/* .curved-iframe-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  width: 300px;
  height: 168.75px;
}

.curved-iframe {
  position: relative;
} */
.justified-text {
  text-align: left;
}