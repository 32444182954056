.headingText{
  margin-top: .05%;
  margin-bottom: 1%;
}
.andrereport{
 text-align: left;
 padding-left: 6vw;
}

.Center_text{
  padding-top: 1%;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  font-size: larger;
  padding-left: 8vw;
  padding-right: 8vw;
}
p {
  margin-top: 3%;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: large;
  font-weight: normal;
}

@media (max-width: 576px) {
  .Center_text  {
    font-size: 20px;
  }
  p {
    font-size: 16px;
  }

}

@media (min-width: 576px) and (max-width: 768px) {
  .Center_text {
    font-size: 24px;
  }
  p {
    font-size: 18px;
  }
}

@media (min-width: 768px) and (max-width: 1400px) {
  .Center_text {
    font-size: 26px;
  }
  p {
    font-size: 20px;
  }
 
}
@media (min-width: 1400px) {
  .Center_text {
    font-size: 26px;
  }
  p {
    font-size:22px;
  }
 
}