.titleText{
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: bold;
}
.first_text{
font-family:Arial, Helvetica, sans-serif;
font-weight: bold;
}
.second_text{
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  
  }

.p1_text{
  color: darkblue;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-style: italic;
}
.p2_text{

  font-family:Arial, Helvetica, sans-serif;
  
}