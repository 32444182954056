
@media (min-width: 1400px) {
  .homebody{
      max-width: 1400px;
  }   
}
.hometop{
  margin-top: -35px;
}
@media (max-width: 767px) {
  .hometop {
    margin-top: -56px;
  }
}
@media (min-width: 576px) {
  .hometop {
    margin-top: -60px;
  }
}
.introscreen {

  display: flex;
  
  height: 175px;
  background-color: lightsalmon;
  margin-bottom: 20px;
  justify-content: center;
  flex-direction: column;
  
}
/* Set font size for small screens */
@media (max-width: 576px) {
  .introscreen h2 {
    font-size: 20px;
  }
  .introscreen h5 {
    font-size: 16px;
  }
}

/* Set font size for medium screens */
@media (min-width: 576px) and (max-width: 768px) {
  .introscreen h2 {
    font-size: 28px;
  }
  .introscreen h5 {
    font-size: 18px;
  }
}

/* Set font size for large screens */
@media (min-width: 768px) {
  .introscreen h2 {
    font-size: 36px;
  }
  .introscreen h5 {
    font-size: 24px;
  }
}

.image-area {
  
  display: flex;
  flex-direction: row;
  }
  img{
    border-radius: 10px;
  }
.card-img-top {
  border-radius: 5px;
}
.image-text {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  background-color: lightblue;
  height: 400px;
  width: 300px;
  text-align: left;
  color: black;
  border-radius: 0px 10px 10px 0px;
}
h2 {
  margin-left: 10px;
  font-size: bold;
}
p {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 16px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  text-align: left;
  text-justify: inter-word;
}
.card {
  border: none !important;
  width: '18rem';
  margin-bottom: 15px;
}

.cardTitle {
  font-weight: bold;
  font-size: 20px;
  }
/* .phenomena {

  display: flex;
  padding-top: 40px;
  padding-bottom: 40px;
  max-height: 40vh;
  background-color: peru;
  margin-bottom: 20px;
  justify-content: center;
  flex-direction: column;
  word-wrap: break-word;
  
} */
.phenomena {
  display: flex;
  padding: 25px 0;
  background-color: peru;
  margin-bottom: 20px;
  justify-content: center;
  flex-direction: column;
  word-wrap: break-word;
}

@media (max-width: 576px) {
  .phenomena h2 {
    font-size: 20px;
  }
  .phenomena h5 {
    font-size: 14px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .phenomena h2 {
    font-size: 24px;
  }
  .phenomena h5 {
    font-size: 16px;
  }
}

@media (min-width: 768px) and (max-width: 1400px) {
  .phenomena h2 {
    font-size: 26px;
  }
  .phenomena h5 {
    font-size: 18px;
  }
}
.buttonMore {
 display: flex;
 justify-content: center;
}
.phenoma-see-more-button{
  background-color: #ffc800cb;
  color: rgba(83, 7, 7, 0.735);
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease, transform 0.2s ease, box-shadow 0.2s ease;
  text-decoration: none;

}
.phenoma-see-more-button:hover {
  background-color: #0056b3;
  color: #fff;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}
.phenoma-see-more-button:active {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateY(0);
}
.card-img-wrapper {
  overflow: hidden;
  position: relative;
}

.card-img-top {
  width: 100%;
  transition: transform 0.3s ease;
}

.card-img-top:hover {
  transform: scale(1.1);
}

.brighten-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
  z-index: -1; 
}
.second-line {
  opacity: 0;
}





