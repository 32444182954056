body{
  background: #dbdfe2;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #d9dfe3, #d5dadd);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #d6dce1, #d0d5d8); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.form{
  background-color: white;
  border-radius: 5px;
  width: 45%;
  margin: 20px auto;
  padding: 20px;
  /* height: 600px; */
}

.form-body{
  text-align: left;
  padding: 20px 10px;
}

.form-body > *{
  padding: 5px;
  display: flex;
  justify-content: space-between;
}
.form__label{
  width: 30%;
}

.form_input{
  width: 70%;
 
  align-items: right;
  
}

.footer{
  text-align: center;
  display: flex;
  justify-content: space-around;
  margin: auto;
}
.btn1 {
  background-color: lightgreen;
  border-radius: 5px;
  
}
.btn2 {
  background-color: lightsalmon;
  border-radius: 5px;
}