.workpage {
  padding-top: 40px;
  font-size: 26px;
}
@media (min-width: 576px) and (max-width: 768px) {
  .workpage {
    font-size: 22px;
  }
  /* .phenomena h5 {
    font-size: 16px;
  } */
}

@media (min-width: 768px) and (max-width: 1400px) {
  .workpage {
    font-size: 25px;
  }
  /* .phenomena h5 {
    font-size: 18px;
  } */
}