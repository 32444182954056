.bg-myNavBackground{
    color: rgb(240, 255, 203);
    background-color:hsl(208, 100%, 50%);
  
    /* font-size: large; */
    font-weight: 600; 
    color: rgb(255, 255, 240);
    
    
    
}
/* Change the default font color of the NavBrand */
.navbar-brand .nav-link {
    color: hsl(207, 56%, 93%);
  }
  
  /* Change the font color of the NavBrand on hover and focus */
  .navbar-brand .nav-link:hover,
  .navbar-brand .nav-link:focus {
    color: hsl(175, 100%, 50%);
  }
.navbar .dropdown-menu{
    background-color:hsl(208, 100%, 50%);
}
/* Change the dropdown item background color on hover and focus */
.navbar .dropdown-menu .dropdown-item:hover,
.navbar .dropdown-menu .dropdown-item:focus {
  background-color: hsl(208, 83%, 73%);
}
.navbar-nav a {
    color: white !important;
   
  }
/* .navbar-brand,
.navbar-nav .nav-link {
    color: #007BFF;
} */
#layoutBody{
    color: darkmagenta;
}
.navbar{
    min-height: 60px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    color: aliceblue;
}
.container {
    margin-top: 0px;
    margin-bottom: 0px;
}
/* :root {
    --vivid-blue: #007BFF;
    --bright-yellow: #FFC107;
    --neon-green: #39FF14;
    --electric-pink: #FF1493;
    --clean-white: #FFFFFF;
    --dark-gray: #333333;
  }
  
  body {
    background-color: var(--clean-white);
    color: var(--dark-gray);
  }
  
  a {
    color: var(--vivid-blue);
  }
  
  button {
    background-color: var(--neon-green);
    color: var(--clean-white);
  }
  
  .alert {
    background-color: var(--bright-yellow);
    color: var(--dark-gray);
  }
  
  .highlight {
    background-color: var(--electric-pink);
    color: var(--clean-white);
  } */

  .nav-link:hover {
    color: greenyellow !important;
  }
  /* .nav-dropdown:hover > .dropdown-menu {
    display: block;
  } */