/* .contactus {
  margin-top: 10px;
  padding-top: 10px;
  min-height: 100vh;
} */

.footer-bottom {
  
  padding-top: 25px;
  background-color: #d6967b;
  text-align: left;
  
}
.footer-title {
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 18px;
    /* color: #ffffff; */
}
.ContactDetails {
  text-align: left;
}
li {
  margin-left: 20px;
}
.footertext {
    white-space: nowrap;/* Prevent the text from breaking into multiple lines */
    color: #1d0dcb;
    text-decoration: none;
    margin-bottom: 5px;
    font-family: 'Roboto', sans-serif;
    line-height: 1.5;
}
.footertext:hover {
    color: #d0e857;
    text-decoration: underline;
  }