/* 
@media (max-width: 576px) {
  .other-links-texts  {
    font-size: 20px;
  
  }

}

@media (min-width: 576px) and (max-width: 768px) {
  .other-links-texts {
    font-size: 24px;
  }

}

@media (min-width: 768px) and (max-width: 1400px) {
  .other-links-texts {
    font-size: 26px;
    padding: 10px;
  }

}
.link-text {
  text-align: left;
} */
.table-container {
  display: flex;
  justify-content: center;
  width: 50%;
}

.table-container table {
  
  max-width: 100%;
}

@media only screen and (max-width: 768px) {
  .table-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .table-container table {
    width: 90%;
  }
}
.table-heading {
  background-color:#d2d7dd;;
  color: #271e1e;
  font-weight: bold;
}

.table-body {
  background-color: #d2d7dd;

}

.table-row:hover {
  background-color: #b4d6e7;
}

.table-row a {
  color: blue;
  text-decoration: none;
  font-style: italic;
}

.table-row a:hover {
  text-decoration: underline;
}
table td:nth-child(2){
  text-align: left;
}
.table {
  border-radius: 10px;
  overflow: hidden;
}
.links{
  text-align: left;
}