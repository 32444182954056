
@media (min-width: 1400px) {
  .homebody{
      max-width: 1400px;
  }   
}

h4 {
  margin-left: 10px;
  font-size: bold;
}
.Vision_text {
  padding-top: 40px;
}
.Center_text {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 16px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  text-align: center;
  text-justify: inter-word;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 20%;
  padding-right: 20%;
}
.phenomena {
  display: flex;
  padding: 25px 0;
  background-color: peru;
  margin-bottom: 20px;
  justify-content: center;
  flex-direction: column;
  word-wrap: break-word;
}

@media (max-width: 576px) {
  .Vision_text  {
    font-size: 20px;
  }

}

@media (min-width: 576px) and (max-width: 768px) {
  .Vision_text {
    font-size: 24px;
  }

}

@media (min-width: 768px) and (max-width: 1400px) {
  .Vision_text {
    font-size: 26px;
  }
 
}