.curved-iframe-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  width: 300px;
  height: 168.75px;
}

.curved-iframe {
  position: relative;
}