body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.Report_French {
    padding-top: 40px;
}

p {
    text-align: left;
    font-size: 24px;
}
.bg-myNavBackground{
    color: rgb(240, 255, 203);
    background-color:hsl(208, 100%, 50%);
  
    /* font-size: large; */
    font-weight: 600; 
    color: rgb(255, 255, 240);
    
    
    
}
/* Change the default font color of the NavBrand */
.navbar-brand .nav-link {
    color: hsl(207, 56%, 93%);
  }
  
  /* Change the font color of the NavBrand on hover and focus */
  .navbar-brand .nav-link:hover,
  .navbar-brand .nav-link:focus {
    color: hsl(175, 100%, 50%);
  }
.navbar .dropdown-menu{
    background-color:hsl(208, 100%, 50%);
}
/* Change the dropdown item background color on hover and focus */
.navbar .dropdown-menu .dropdown-item:hover,
.navbar .dropdown-menu .dropdown-item:focus {
  background-color: hsl(208, 83%, 73%);
}
.navbar-nav a {
    color: white !important;
   
  }
/* .navbar-brand,
.navbar-nav .nav-link {
    color: #007BFF;
} */
#layoutBody{
    color: darkmagenta;
}
.navbar{
    min-height: 60px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    color: aliceblue;
}
.container {
    margin-top: 0px;
    margin-bottom: 0px;
}
/* :root {
    --vivid-blue: #007BFF;
    --bright-yellow: #FFC107;
    --neon-green: #39FF14;
    --electric-pink: #FF1493;
    --clean-white: #FFFFFF;
    --dark-gray: #333333;
  }
  
  body {
    background-color: var(--clean-white);
    color: var(--dark-gray);
  }
  
  a {
    color: var(--vivid-blue);
  }
  
  button {
    background-color: var(--neon-green);
    color: var(--clean-white);
  }
  
  .alert {
    background-color: var(--bright-yellow);
    color: var(--dark-gray);
  }
  
  .highlight {
    background-color: var(--electric-pink);
    color: var(--clean-white);
  } */

  .nav-link:hover {
    color: greenyellow !important;
  }
  /* .nav-dropdown:hover > .dropdown-menu {
    display: block;
  } */
.select{
    position: relative;
    padding: 0.5rem 1rem;
}
.select ::before{
    background-color:hsl(208, 100%, 50%);   
    transition: -webkit-transform 0.3s ease-in-out;   
    transition: transform 0.3s ease-in-out;   
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    z-index: -1;   
    opacity: 0;
    content: '';
    position: absolute; 
       
}
.select:hover{
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 1;
}
.my-select
{
    background-color:rgb(47, 238, 255);
    border-radius: 7px;
    padding: 2px 6px;

    
}
.App {
  text-align: center;
  margin: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.App .page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App .content-wrapper {
  flex: 1 1;
}










@media (min-width: 1400px) {
  .homebody{
      max-width: 1400px;
  }   
}
.hometop{
  margin-top: -35px;
}
@media (max-width: 767px) {
  .hometop {
    margin-top: -56px;
  }
}
@media (min-width: 576px) {
  .hometop {
    margin-top: -60px;
  }
}
.introscreen {

  display: flex;
  
  height: 175px;
  background-color: lightsalmon;
  margin-bottom: 20px;
  justify-content: center;
  flex-direction: column;
  
}
/* Set font size for small screens */
@media (max-width: 576px) {
  .introscreen h2 {
    font-size: 20px;
  }
  .introscreen h5 {
    font-size: 16px;
  }
}

/* Set font size for medium screens */
@media (min-width: 576px) and (max-width: 768px) {
  .introscreen h2 {
    font-size: 28px;
  }
  .introscreen h5 {
    font-size: 18px;
  }
}

/* Set font size for large screens */
@media (min-width: 768px) {
  .introscreen h2 {
    font-size: 36px;
  }
  .introscreen h5 {
    font-size: 24px;
  }
}

.image-area {
  
  display: flex;
  flex-direction: row;
  }
  img{
    border-radius: 10px;
  }
.card-img-top {
  border-radius: 5px;
}
.image-text {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  background-color: lightblue;
  height: 400px;
  width: 300px;
  text-align: left;
  color: black;
  border-radius: 0px 10px 10px 0px;
}
h2 {
  margin-left: 10px;
  font-size: bold;
}
p {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 16px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  text-align: left;
  text-justify: inter-word;
}
.card {
  border: none !important;
  width: '18rem';
  margin-bottom: 15px;
}

.cardTitle {
  font-weight: bold;
  font-size: 20px;
  }
/* .phenomena {

  display: flex;
  padding-top: 40px;
  padding-bottom: 40px;
  max-height: 40vh;
  background-color: peru;
  margin-bottom: 20px;
  justify-content: center;
  flex-direction: column;
  word-wrap: break-word;
  
} */
.phenomena {
  display: flex;
  padding: 25px 0;
  background-color: peru;
  margin-bottom: 20px;
  justify-content: center;
  flex-direction: column;
  word-wrap: break-word;
}

@media (max-width: 576px) {
  .phenomena h2 {
    font-size: 20px;
  }
  .phenomena h5 {
    font-size: 14px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .phenomena h2 {
    font-size: 24px;
  }
  .phenomena h5 {
    font-size: 16px;
  }
}

@media (min-width: 768px) and (max-width: 1400px) {
  .phenomena h2 {
    font-size: 26px;
  }
  .phenomena h5 {
    font-size: 18px;
  }
}
.buttonMore {
 display: flex;
 justify-content: center;
}
.phenoma-see-more-button{
  background-color: #ffc800cb;
  color: rgba(83, 7, 7, 0.735);
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease, box-shadow 0.2s ease, -webkit-transform 0.2s ease;
  transition: background-color 0.2s ease, transform 0.2s ease, box-shadow 0.2s ease;
  transition: background-color 0.2s ease, transform 0.2s ease, box-shadow 0.2s ease, -webkit-transform 0.2s ease;
  text-decoration: none;

}
.phenoma-see-more-button:hover {
  background-color: #0056b3;
  color: #fff;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}
.phenoma-see-more-button:active {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.card-img-wrapper {
  overflow: hidden;
  position: relative;
}

.card-img-top {
  width: 100%;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.card-img-top:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.brighten-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
  z-index: -1; 
}
.second-line {
  opacity: 0;
}







/* .imageAlign{
    height: 400px;
    width: 300px;
} */
/* .image-box {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid #ccc;
    border-radius: 10px;
    padding: 7px;
    background-color: purple;
    margin: 0 10px; 
    width: 225px; 
    height: 300px; 
    overflow: hidden; 
  } */
  .carousel-item-no-padding {
    padding: 0 !important; /* Remove the default padding */
  }
  
  /* .carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: transparent;
  } */
  .image-box img {
    display: block;
    width: 100%;
    height: auto;
  }
  .carousel-container {
    position: relative;
    padding: 30px;
    background-image: linear-gradient(135deg, #f8f9fa, #cfd9df, #e2ebf0);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .carousel-container .react-multiple-carousel__arrow {
    background-color: rgba(255, 255, 255, 0.538);
    border-radius: 50%;
    padding: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: all 0.3s;
  }
  
  .carousel-container .react-multiple-carousel__arrow:hover {
    background-color: rgba(255, 255, 255, 0.922);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  }
  .carousel-image {
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    max-width: 100%;
    height: auto;
  }
  
  .carousel-image:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
/* .contactus {
  margin-top: 10px;
  padding-top: 10px;
  min-height: 100vh;
} */

.footer-bottom {
  
  padding-top: 25px;
  background-color: #d6967b;
  text-align: left;
  
}
.footer-title {
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 18px;
    /* color: #ffffff; */
}
.ContactDetails {
  text-align: left;
}
li {
  margin-left: 20px;
}
.footertext {
    white-space: nowrap;/* Prevent the text from breaking into multiple lines */
    color: #1d0dcb;
    text-decoration: none;
    margin-bottom: 5px;
    font-family: 'Roboto', sans-serif;
    line-height: 1.5;
}
.footertext:hover {
    color: #d0e857;
    text-decoration: underline;
  }
body{
  background: #dbdfe2;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #d6dce1, #d0d5d8); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.form{
  background-color: white;
  border-radius: 5px;
  width: 45%;
  margin: 20px auto;
  padding: 20px;
  /* height: 600px; */
}

.form-body{
  text-align: left;
  padding: 20px 10px;
}

.form-body > *{
  padding: 5px;
  display: flex;
  justify-content: space-between;
}
.form__label{
  width: 30%;
}

.form_input{
  width: 70%;
 
  align-items: right;
  
}

.footer{
  text-align: center;
  display: flex;
  justify-content: space-around;
  margin: auto;
}
.btn1 {
  background-color: lightgreen;
  border-radius: 5px;
  
}
.btn2 {
  background-color: lightsalmon;
  border-radius: 5px;
}
.errorlogin {
  display: flex;
  justify-content: center;
  color: red;
}
.testimonials {
  
  margin-top: 20px;
  
}
.custom-class {
  background-color: hsl(240, 7%, 86%);
  
}
.song {
  width: auto ;
  display: flex;
  flex-direction: column;
  align-items: left;

}
.Button {
  width: 40px;
}
.custom-cardtext {
  text-align: center;
}
.custom-cardtitle{
  font-weight: bold;
}
.custom-card-spacing {
 
  padding: 3rem;
 
}
.video-wrapper{
  border-radius: 7px;
}
.testimonials .titlefont {
  font-size: 24px !important;
  font-weight: bold;
}

/* .curved-iframe-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  width: 300px;
  height: 168.75px;
}

.curved-iframe {
  position: relative;
} */
.justified-text {
  text-align: left;
}

@media (min-width: 1400px) {
  .homebody{
      max-width: 1400px;
  }   
}

h4 {
  margin-left: 10px;
  font-size: bold;
}
.Vision_text {
  padding-top: 40px;
}
.Center_text {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 16px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  text-align: center;
  text-justify: inter-word;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 20%;
  padding-right: 20%;
}
.phenomena {
  display: flex;
  padding: 25px 0;
  background-color: peru;
  margin-bottom: 20px;
  justify-content: center;
  flex-direction: column;
  word-wrap: break-word;
}

@media (max-width: 576px) {
  .Vision_text  {
    font-size: 20px;
  }

}

@media (min-width: 576px) and (max-width: 768px) {
  .Vision_text {
    font-size: 24px;
  }

}

@media (min-width: 768px) and (max-width: 1400px) {
  .Vision_text {
    font-size: 26px;
  }
 
}
.headingText{
  margin-top: .05%;
  margin-bottom: 1%;
}
.andrereport{
 text-align: left;
 padding-left: 6vw;
}

.Center_text{
  padding-top: 1%;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  font-size: larger;
  padding-left: 8vw;
  padding-right: 8vw;
}
p {
  margin-top: 3%;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: large;
  font-weight: normal;
}

@media (max-width: 576px) {
  .Center_text  {
    font-size: 20px;
  }
  p {
    font-size: 16px;
  }

}

@media (min-width: 576px) and (max-width: 768px) {
  .Center_text {
    font-size: 24px;
  }
  p {
    font-size: 18px;
  }
}

@media (min-width: 768px) and (max-width: 1400px) {
  .Center_text {
    font-size: 26px;
  }
  p {
    font-size: 20px;
  }
 
}
@media (min-width: 1400px) {
  .Center_text {
    font-size: 26px;
  }
  p {
    font-size:22px;
  }
 
}
p {
  font-size: 18;
}
.frandrelink{
  padding-left: 5px;
  padding-right: 5px;
}

.ProjectBujum {
  margin-top: 40px;
}
h4 {
  margin-left: 10px;
  font-size: bold;
  padding-top: 40px;
}
.custom-card-spacing {
 
  padding: 2rem;
}
.curved-iframe-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  width: 300px;
  height: 168.75px;
}

.curved-iframe {
  position: relative;
}
.custom-class {
  
  align-items: center;
  padding:10px;

}
.bold-title {
  font-weight: bold;
  padding-bottom: 10px;
}
.custom-card-spacing {
 
  padding: 2rem;
}
/* 
@media (max-width: 576px) {
  .other-links-texts  {
    font-size: 20px;
  
  }

}

@media (min-width: 576px) and (max-width: 768px) {
  .other-links-texts {
    font-size: 24px;
  }

}

@media (min-width: 768px) and (max-width: 1400px) {
  .other-links-texts {
    font-size: 26px;
    padding: 10px;
  }

}
.link-text {
  text-align: left;
} */
.table-container {
  display: flex;
  justify-content: center;
  width: 50%;
}

.table-container table {
  
  max-width: 100%;
}

@media only screen and (max-width: 768px) {
  .table-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .table-container table {
    width: 90%;
  }
}
.table-heading {
  background-color:#d2d7dd;;
  color: #271e1e;
  font-weight: bold;
}

.table-body {
  background-color: #d2d7dd;

}

.table-row:hover {
  background-color: #b4d6e7;
}

.table-row a {
  color: blue;
  text-decoration: none;
  font-style: italic;
}

.table-row a:hover {
  text-decoration: underline;
}
table td:nth-child(2){
  text-align: left;
}
.table {
  border-radius: 10px;
  overflow: hidden;
}
.links{
  text-align: left;
}
.workpage {
  padding-top: 40px;
  font-size: 26px;
}
@media (min-width: 576px) and (max-width: 768px) {
  .workpage {
    font-size: 22px;
  }
  /* .phenomena h5 {
    font-size: 16px;
  } */
}

@media (min-width: 768px) and (max-width: 1400px) {
  .workpage {
    font-size: 25px;
  }
  /* .phenomena h5 {
    font-size: 18px;
  } */
}
/* 
@media (max-width: 576px) {
  .other-links-texts  {
    font-size: 20px;
  
  }

}

@media (min-width: 576px) and (max-width: 768px) {
  .other-links-texts {
    font-size: 24px;
  }

}

@media (min-width: 768px) and (max-width: 1400px) {
  .other-links-texts {
    font-size: 26px;
    padding: 10px;
  }

}
.link-text {
  text-align: left;
} */
.table-container {
  display: flex;
  justify-content: center;
  width: 50%;
}

.table-container table {
  
  max-width: 100%;
}

@media only screen and (max-width: 768px) {
  .table-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .table-container table {
    width: 90%;
  }
}
.table-heading {
  background-color:#d2d7dd;;
  color: #271e1e;
  font-weight: bold;
}

.table-body {
  background-color: #d2d7dd;

}

.table-row:hover {
  background-color: #b4d6e7;
}

.table-row a {
  color: blue;
  text-decoration: none;
  font-style: italic;
}

.table-row a:hover {
  text-decoration: underline;
}
table td:nth-child(2){
  text-align: left;
}
.table {
  border-radius: 10px;
  overflow: hidden;
}

.titleText{
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: bold;
}
.first_text{
font-family:Arial, Helvetica, sans-serif;
font-weight: bold;
}
.second_text{
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  
  }

.p1_text{
  color: darkblue;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-style: italic;
}
.p2_text{

  font-family:Arial, Helvetica, sans-serif;
  
}

