.select{
    position: relative;
    padding: 0.5rem 1rem;
}
.select ::before{
    background-color:hsl(208, 100%, 50%);   
    transition: transform 0.3s ease-in-out;
    z-index: -1;   
    opacity: 0;
    content: '';
    position: absolute; 
       
}
.select:hover{
    transform: scale(1.2);
    opacity: 1;
}
.my-select
{
    background-color:rgb(47, 238, 255);
    border-radius: 7px;
    padding: 2px 6px;

    
}