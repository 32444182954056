
/* .imageAlign{
    height: 400px;
    width: 300px;
} */
/* .image-box {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid #ccc;
    border-radius: 10px;
    padding: 7px;
    background-color: purple;
    margin: 0 10px; 
    width: 225px; 
    height: 300px; 
    overflow: hidden; 
  } */
  .carousel-item-no-padding {
    padding: 0 !important; /* Remove the default padding */
  }
  
  /* .carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: transparent;
  } */
  .image-box img {
    display: block;
    width: 100%;
    height: auto;
  }
  .carousel-container {
    position: relative;
    padding: 30px;
    background-image: linear-gradient(135deg, #f8f9fa, #cfd9df, #e2ebf0);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .carousel-container .react-multiple-carousel__arrow {
    background-color: rgba(255, 255, 255, 0.538);
    border-radius: 50%;
    padding: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: all 0.3s;
  }
  
  .carousel-container .react-multiple-carousel__arrow:hover {
    background-color: rgba(255, 255, 255, 0.922);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  }
  .carousel-image {
    transition: transform 0.3s ease-in-out;
    max-width: 100%;
    height: auto;
  }
  
  .carousel-image:hover {
    transform: scale(1.1);
  }